<template>
    <div class="protocol">
        <p>
            <span style="font-size:16px;line-height:2;"><b>一、适用范围</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1. 本政策仅适用于良药邦APP软件、网站、良药邦微信公众号、良药邦微信小程序等客户端服务（以下简称"良药邦客户端"）的运营主体及良药邦客户端服务可能存在的运营关联单位，具体指良药邦（湖北）医药科技有限公司及其关联公司（以下统称"良药邦"）。使用前述良药邦相关客户端服务的使用人在本政策中称为"用户"，或称为"您"。</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;"><b>2. 请您在使用良药邦客户端的各项产品及服务前，仔细阅读并充分理解本隐私保护政策注册协议，重点内容我们已经用粗体表示，请您特别关注。您在点击"确认"按钮后，本政策即构成对双方有约束力的法律文件，即表示您同意良药邦按照本政策收集、使用、处理和存储您的相关信息。 如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第8条提供的联系方式与我们联系。</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">3. 良药邦深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;"><b>二、隐私权政策</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1、我们如何收集和使用您的个人信息</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">2、我们如何使用 Cookie 和同类技术</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">3、我们如何共享、转让、公开披露您的个人信息</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">4、我们如何保护您的个人信息</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">5、您如何管理您的信息</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">6、我们如何保护未成年人的信息</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">7、本政策如何更新</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">8、如何联系我们</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;"><b>三、隐私保护政策具体内容</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;"><b>1. 我们如何收集和使用信息</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下内容：</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1.1注册、登录</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">当您注册、登录良药邦客户端并使用良药邦客户端相关功能及相关服务时，您可以通过手机验证码的方式注册、登录良药邦客户端，您也可以使用第三方账户注册、登录并使用，如您使用第三方账户注册登录良药邦客户端的，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称、地区、身份和性别信息）。</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1.2浏览</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">您可浏览的内容包括药品信息等信息。为此，<b>我们可能会收集您使用良药邦客户端时的设备信息，如设备型号、唯一设备标识符、操作系统、电信运营商等软硬件信息。</b>我们还可能收集您的浏览器类型，以此来为您提供信息展示的最优方案。此外，在您使用浏览功能的过程中，我们会自动收集您使用良药邦客户端的详细情况，并作为有关的<b>网络日志保存，包括但不限于您输入的搜索关键词信息和点击的链接，您浏览的内容及评论信息，访问的日期和时间、及您请求的网页记录、操作系统、软件版本号、 IP 信息。 在此过程中，我们会收集您的浏览记录。</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1.3搜索功能</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">当您使用良药邦客户端提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用良药邦客户端服务时所阅览或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善良药邦客户端产品和服务，我们可能会使用浏览器网络存储机制和应用数据缓存，收集您设备上的信息并进行本地存储。<b>该等关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本政策的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1.4购物功能</span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">您可在良药邦客户端在线购买相应的医疗服务和商品。在您使用该功能的过程中<b>我们可能会收集您的定位信息；</b>在支付过程中，<b>我们可能会收集您的第三方支付帐号(例如支付宝帐号、微信支付帐号或其他形式的银行卡信息)用于付款验证。还需要收集您的收货地址信息，包括收货人、地址、手机号码。</b></span>
        </p>
        <p>
            <span style="font-size:16px;line-height:2;">1.5安全运行</span>
        </p>
        <p><span style="font-size:16px;line-height:2;">1.5.1 安全保障功能我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。为实现安全保障功能所收集的信息是必要信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">1.5.2 设备信息与日志信息</span></p>
        <p><span style="font-size:16px;line-height:2;">a.为了保障软件与服务的安全运行，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP 地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、手机状态信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">b.为了预防恶意程序及安全运营所必需，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。</span></p>
        <p><span style="font-size:16px;line-height:2;">c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联公司、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。</span></p>
        <p><span style="font-size:16px;line-height:2;">当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</span></p>
        <p><span style="font-size:16px;line-height:2;">当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>2.我们如何使用Cookie等技术</b></span></p>
        <p><span style="font-size:16px;line-height:2;">为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的登录状态等数据，提升服务/产品质量及优化用户体验。我们不会将 Cookie用于本政策所述目的之外的任何用途。</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>3.我们如何共享、转让、公开披露您的个人信息</b></span></p>
        <p><span style="font-size:16px;line-height:2;">3.1共享</span></p>
        <p><span style="font-size:16px;line-height:2;">我们不会向良药邦以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</span></p>
        <p><span style="font-size:16px;line-height:2;">3.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">3.1.2我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">3.1.3与我们的附属公司共享：基于为您服务的目的，您的个人信息可能会与良药邦的附属公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span></p>
        <p><span style="font-size:16px;line-height:2;">3.1.4 与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span></p>
        <p><span style="font-size:16px;line-height:2;">目前，我们的授权合作伙伴包括但不限于以下类型：</span></p>
        <p><span style="font-size:16px;line-height:2;">1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</span></p>
        <p><span style="font-size:16px;line-height:2;">2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span></p>
        <p><span style="font-size:16px;line-height:2;">3.2转让</span></p>
        <p><span style="font-size:16px;line-height:2;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span></p>
        <p><span style="font-size:16px;line-height:2;">3.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span></p>
        <p><span style="font-size:16px;line-height:2;">3.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span></p>
        <p><span style="font-size:16px;line-height:2;">3.3公开披露</span></p>
        <p><span style="font-size:16px;line-height:2;">我们仅会在以下情况下，公开披露您的个人信息：</span></p>
        <p><span style="font-size:16px;line-height:2;">3.3.1获得您明确同意后；</span></p>
        <p><span style="font-size:16px;line-height:2;">3.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景：</span></p>
        <p><span style="font-size:16px;line-height:2;">a.与国家安全、国防安全直接相关的；</span></p>
        <p><span style="font-size:16px;line-height:2;">b.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p><span style="font-size:16px;line-height:2;">c.与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p><span style="font-size:16px;line-height:2;">d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
        <p><span style="font-size:16px;line-height:2;">e.所收集的您的个人信息是您自行向社会公众公开的；</span></p>
        <p><span style="font-size:16px;line-height:2;">f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>
        <p><span style="font-size:16px;line-height:2;">g.根据您的要求签订或履行合同所必需的；</span></p>
        <p><span style="font-size:16px;line-height:2;">h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；</span></p>
        <p><span style="font-size:16px;line-height:2;">i.为合法的新闻报道所必需的；</span></p>
        <p><span style="font-size:16px;line-height:2;">j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></p>
        <p><span style="font-size:16px;line-height:2;">k.法律法规规定的其他情形。</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</b></span></p>
        <p><span style="font-size:16px;line-height:2;">4.我们如何存储和保护信息</span></p>
        <p><span style="font-size:16px;line-height:2;">4.1 技术措施与数据保护措施</span></p>
        <p><span style="font-size:16px;line-height:2;">我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">4.2 安全事件处理</span></p>
        <p><span style="font-size:16px;line-height:2;">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span></p>
        <p><span style="font-size:16px;line-height:2;">5.您如何管理您的信息</span></p>
        <p><span style="font-size:16px;line-height:2;">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span></p>
        <p><span style="font-size:16px;line-height:2;">5.1查询、更正您的个人信息</span></p>
        <p><span style="font-size:16px;line-height:2;">您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：</span></p>
        <p><span style="font-size:16px;line-height:2;">1）、登录良药邦，进入"我的良药邦"首页，点击个人信息管理菜单进行查询、更正个人资料及个人账户相关信息；</span></p>
        <p><span style="font-size:16px;line-height:2;">2）、登录手机良药邦客户端，进入"我的良药邦-设置"对个人资料、账号及收货地址等信息进行查询、更正或补充；</span></p>
        <p><span style="font-size:16px;line-height:2;">3）、如果您无法通过上述方式更正这些个人信息，您可以通过本政策第8条提供的联系方式与我们联系进行查询或更正。</span></p>
        <p><span style="font-size:16px;line-height:2;">5.2删除您的个人信息</span></p>
        <p><span style="font-size:16px;line-height:2;">在以下情形中，您可以向我们提出删除个人信息的请求：</span></p>
        <p><span style="font-size:16px;line-height:2;">1）、如果我们处理个人信息的行为违反法律法规；</span></p>
        <p><span style="font-size:16px;line-height:2;">2）、如果我们收集、使用您的个人信息，却未征得您的同意；</span></p>
        <p><span style="font-size:16px;line-height:2;">3）、如果我们处理个人信息的行为违反了与您的约定；</span></p>
        <p><span style="font-size:16px;line-height:2;">4）、如果您不再使用我们的产品或服务，或您注销了账号；</span></p>
        <p><span style="font-size:16px;line-height:2;">5）、如果我们不再为您提供产品或服务。</span></p>
        <p><span style="font-size:16px;line-height:2;">若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</b></span></p>
        <p><span style="font-size:16px;line-height:2;">5.3改变您授权同意的范围</span></p>
        <p><span style="font-size:16px;line-height:2;">每个业务功能需要一些基本的个人信息才能得以完成，对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意，可通过本政策第8条提供的联系方式与我们联系进行授权变更。</span></p>
        <p><span style="font-size:16px;line-height:2;">当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span></p>
        <p><span style="font-size:16px;line-height:2;">5.4个人信息主体注销账户</span></p>
        <p><span style="font-size:16px;line-height:2;">您随时可注销此前注册的账户，可通过台式电脑登陆良药邦账号点击"我的良药邦"，进入"个人信息管理"→"安全中心"后点击"账号注销"进行注销。</span></p>
        <p><span style="font-size:16px;line-height:2;">在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</span></p>
        <p><span style="font-size:16px;line-height:2;">5.5约束信息系统自动决策</span></p>
        <p><span style="font-size:16px;line-height:2;">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</span></p>
        <p><span style="font-size:16px;line-height:2;">6.我们如何保护未成年人的信息</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</b></span></p>
        <p><span style="font-size:16px;line-height:2;">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span></p>
        <p><span style="font-size:16px;line-height:2;">7.本政策的适用及更新</span></p>
        <p><span style="font-size:16px;line-height:2;">我们可能会不时对《良药邦客户端隐私保护政策》进行修订。<b>请您注意，只有在您确认修订后的《良药邦客户端隐私保护政策》后，我们才会按照修订后的《良药邦客户端隐私保护政策》收集、使用、处理和存储您的个人信息；您可以选择不同意修订后的《良药邦客户端隐私保护政策》，但可能导致您无法使用良药邦客户端的部分产品及服务功能。</b></span></p>
        <p><span style="font-size:16px;line-height:2;">未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面上发布对隐私权政策所做的任何变更。</span></p>
        <p><span style="font-size:16px;line-height:2;">对于重大变更，我们还会提供更为显著的通知（包括对于某些服务， 我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。</span></p>
        <p><span style="font-size:16px;line-height:2;">本政策所指的重大变更包括但不限于：</span></p>
        <p><span style="font-size:16px;line-height:2;">1）、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></p>
        <p><span style="font-size:16px;line-height:2;">2）、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span></p>
        <p><span style="font-size:16px;line-height:2;">3）、个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
        <p><span style="font-size:16px;line-height:2;">4）、您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
        <p><span style="font-size:16px;line-height:2;">5）、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></p>
        <p><span style="font-size:16px;line-height:2;">6）、个人信息安全影响评估报告表明存在高风险时。</span></p>
        <p><span style="font-size:16px;line-height:2;"><b>8.如何联系我们</b></span></p>
        <p><span style="font-size:16px;line-height:2;">如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</span></p>
        <p><span style="font-size:16px;line-height:2;">8.1您可以通过在线客服或电话与我们联系，联系电话：0717-6489486</span></p>
        <p><span style="font-size:16px;line-height:2;">8.2如您发现个人信息可能被泄露，您可以通过3309405600@qq.com投诉举报。</span></p>
        <p><span style="font-size:16px;line-height:2;">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，<b>您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</b></span></p>
    </div>
</template>

<script>
export default {
    name: "Protocol", // 客户端隐私保护政策
}
</script>

<style lang="scss" scoped>
.protocol {
    padding: 20px;
}
</style>
