<template>
    <div class="service">
        <p><span><b>1.特别声明</b></span></p>
        <p>
            <span>1.1良药邦（湖北）医药科技有限公司(以下称"良药邦公司"或“我们")同意按照本协议的规定及其不时发布的操作规则提供良药邦手机应用软件服务及网站平台(以下称"良药邦")。为获得服务，良药邦使用人(以下称“用户"或"您“)应当基于了解本协议全部内容，在独立思考的基础上认可、同意本协议的全部条款并按照页面上的提示完成全部的注册程序，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体加下划线标识，您应重点阅读。<b>本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定，用户在进行注册程序过程中点击\同意\按钮即表示用户完全接受本协议。</b>如您对协议有任何疑问，可向良药邦客服咨询，<b>如果您不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，您应立即停止注册程序包括但不限于不要进行后续操作、不要接受本协议，不使用本服务。</b></span>
        </p>
        <p>
            <span>1.2为提高用户的使用感受和满意度，用户同意我们将基于用户的操作行为对用户数据进行调查研究和分析，从而进一步优化良药邦的服务。</span>
        </p>
        <p><span>2.服务内容及费用</span></p>
        <p>
            <span>2.1良药邦的具体服务由良药邦公司根据实际情况提供，我们保留变更、中断或终止部分或全部服务的权利。</span>
        </p>
        <p>
            <span>2.2我们为良药邦平台向您提供的服务付出了大量的成本，除我们明示的收费业务外，良药邦向用户提供的服务目前是免费的。<b>如未来良药邦向您收取合理费用，良药邦会采取合理途径并提前通过法定程序以本协议约定的有效方式通知您，确保您有充分选择的权利。对于收费业务，良药邦会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费服务。如用户拒绝支付相关费用，则良药邦有权不向用户提供该等收费服务。</b></span>
        </p>
        <p>
            <span>2.3您理解并同意，良药邦仅提供相关的技术服务，除此之外与相关服务有关的设备(如手机及其他与接入移动网有关的装置)及所需的费用(如为使用移动网而支付的手机费、电话费或上网费)均应由用户自行负担。</span>
        </p>
        <p>
            <span>2.4采购服务为良药邦仅为具备相关经营资质的单位用户与供应商之间的信息撮合，提供药品、医疗器械交易信息与交易服务，该等用户在良药邦使用采购服务应当先仔细阅读及同意《良药邦采购服务补充协议》。《良药邦采购服务补充协议》为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用采购服务，视为您同意上述补充协议。</span>
        </p>
        <p>
            <span><b>2.5我们在此提示，您在使用我们服务期间应当遵守中华人民共和国的法律法规，不得危害网络安全，不得利用我们的服务从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。尽管有前述提示，我们不对您使用我们服务的用途和目的承担任何责任。</b></span>
        </p>
        <p><span>3.使用规则</span></p>
        <p>
            <span>3.1用户在完成注册程序或以其他良药邦允许的方式实际使用服务时，<b>应当具备中华人民共和国法律规定的与用户行为相适应的民事行为能力；如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本站；如用户代表一家公司或其他法律主体在良药邦注册，</b>则用户声明和保证已获得完整的授权、有权使该公司或该法律主体受本协议的约束。采购服务仅供具备相关经营资质的单位用户使用，任何无证单位或个人用户盗用他人名义、资质或伪造授权进行药品采购均属于违法行为，如您不具备前述主体资格或相应的授权，应承担因此导致的一切后果，且良药邦有权终止向您提供服务并注销您的账户并公告处理结果，对于涉嫌违反法律法注销您的账户并公告处理结果，对于涉嫌违反法律法规、涉嫌违法犯罪的行为，良药邦有权保存有关记录，并依法向有关主管行政部门报告、配合有关行政主管部门调查，并可能依法移送司法机关。</span>
        </p>
        <p>
            <span>3.2用户使用手机号为账户注册良药邦，该帐号和密码由用户负责保管，<b>用户登录平台后的指令将视为用户所为，良药邦任何时候均不会主动要求用户提供账户密码，用户应确保在每个上网时段结束时退出登录并以正确步骤离开良药邦平台；</b>除良药邦平台存在过错外，用户应对账户项下的所有行为结果(包括但不限于在线签署各类协议、发布信息、询/报价、采购产品、订购服务及披露信息等)负责。账户因用户主动泄露或因用户遭受他人攻击、诈骗等行为导致的损失及后果，我们并不承担责任，用户应通过司法、行政等救济途径向侵权行为人追偿。</span>
        </p>
        <p>
            <span>3.3用户进行实名认证和获取终端码时，应提供真实、有效、合法的证件，如证件被查明作假，良药邦有权冻结用户通过良药邦获得的收益，封止和删除帐号。</span>
        </p>
        <p>
            <span>3.4用户在使用良药邦过程中，必须遵循以下原则：</span>
        </p>
        <p>
            <span>·遵守中国有关的法律和法规(例如：所设置的账户登录名及昵称不得违反国家法律法规，否则良药邦可回收您的登录名及昵称)；</span>
        </p>
        <p>
            <span>·不得为任何非法目的或以任何非法方式而使用良药邦(例如：证件造假、盗用他人账号、名义或资质)；</span>
        </p>
        <p>
            <span><b>·除非法律法规另有规定，用户不得转让、出借、出租良药邦账户、主动泄露密码或以任何方式供他人使用，否则我们有权追究用户的违约责任，且由此产生的相应责任均由用户承担；</b></span>
        </p>
        <p>
            <span>·如用户发现任何非法使用帐号的情况，应立即通告良药邦，我们会第一时间采取行动，但用户理解我们对用户的任何请求采取行动均需要合理时间，且应用户请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除我们存在法定过错外，我们不承担责任。</span>
        </p>
        <p>
            <span>3.5良药邦公司保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利，用户对此予以知悉并充分认可。</span>
        </p>
        <p>
            <span>3.6鉴于目前电子商务的软硬件环境尚未成熟，且有关安全保护技术和措施在其本身不断得到改进和完善的同时，也在不断面临新的挑战。因此，您使用本服务将存在一定的风险，该等风险包括但不限于：您的用户账号和密码被他人冒用、盗用、非法使用；或不法分子盗用您的银行卡；或对方拒绝付款。这些风险均会给您造成经济损失，除非有足够官方证据可证明良药邦公司对损失的形成存在故意或重大过失，良药邦公司将不承担任何责任。</span>
        </p>
        <p>
            <span>3.7对本网站上所有内容包括但不限于网站运营或本网站上的信息、内容、材料或产品，不提供明示或暗示的担保。无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）。</span>
        </p>
        <p><span>4.知识产权</span></p>
        <p>
            <span>4.1良药邦提供的服务内容可能包括：文字、声音、图片、图表等。所有这些内容均为良药邦公司或授权良药邦公司使用的合法权利人所有，除非事先经良药邦公司或其权利人的合法授权，任何人皆不得擅自以任何形式使用，否则良药邦可立即终止向用户提供产品和服务，并依法追究其法律责任，赔偿良药邦一切损失(包括直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失)并消除对良药邦造成的不良影响。</span>
        </p>
        <p>
            <span>4.2用户一旦接受本协议，即表明该用户主动将其在任何时间段在本站发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、 翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给良药邦公司所有，用户同意良药邦公司有权就任何主体侵权而单独提起诉讼。</span>
        </p>
        <p>
            <span>5.隐私保护</span>
        </p>
        <p>
            <span>5.1您理解并同意，当您在申请成为用户以及在使用服务时，良药邦不可避免地获悉、收集了您的个人信息，保护您的个人信息是良药邦的一项基本政策，良药邦保证不对外公开或向第三方提供用户申请资料及用户在使用服务时存储在良药邦的个人信息，但下列情况除外：</span>
        </p>
        <p>
            <span>·事先获得用户的明确授权；</span>
        </p>
        <p>
            <span>·根据有关的法律法规要求；</span>
        </p>
        <p>
            <span>·按照相关政府主管部门的要求；</span>
        </p>
        <p>
            <span>·为维护社会公众的利益；</span>
        </p>
        <p>
            <span>·向可信赖的合作伙伴披露，让他们根据我们的指示并遵循本协议以及其他任何相应的保密和安全措施来为我们处理这些信息；</span>
        </p>
        <p>
            <span>·为了提升服务效率、降低服务成本或提高服务质量，我们可能会委托专业服务机构提供协助，因此我们在必要范围内向其提供您的部分信息；</span>
        </p>
        <p>
            <span>·为维护良药邦及其关联方的合法权益。</span>
        </p>
        <p>
            <span>5.2个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，在不透露单个用户个人信息资料的前提下，为了给用户提供更好的服务，良药邦可能会对整个用户数据库进行分析并对用户数据库进行商业上的利用(包括但不限于公布、分析或以其它合法方式使用用户访问量、访问时段、用户偏好等用户数据信息)。</span>
        </p>
        <p>
            <span>5.3需要特别说明的是，良药邦隐私保护政策不适用于其他第三方向您提供的服务，例如良药邦的卖家依托平台向您提供服务时，您向卖家提供的个人信息不适用良药邦隐私保护政策，请您特别注意，在使用良药邦平台服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。</span>
        </p>
        <p>
            <span>6.免责声明</span>
        </p>
        <p>
            <span>6.1您理解并同意，良药邦依照法律规定履行保障基础义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，良药邦并不承担相应的违约责任：</span>
        </p>
        <p>
            <span>(一)因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</span>
        </p>
        <p>
            <span>(二)因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</span>
        </p>
        <p>
            <span>(三)因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</span>
        </p>
        <p>
            <span>6.2如果良药邦发现用户有下列任一行为的，有权根据相应网络服务的公约或守则的规定，采取相应措施：包括但不限于对该用户帐号的冻结、终止、删除；用户在此承诺良药邦有权作出上述行为，并承诺不就上述行为要求良药邦做任何补偿或退费：</span>
        </p>
        <p>
            <span>·用户提供虚假注册信息；</span>
        </p>
        <p>
            <span>·用户违反本协议中规定的使用规则；</span>
        </p>
        <p>
            <span>·通过非法手段、不正当手段或其他不公平的手段使用良药邦的服务或参与良药邦活动；</span>
        </p>
        <p>
            <span>·有损害良药邦正当利益的行为；</span>
        </p>
        <p>
            <span>·有严重损害其他用户的行为；</span>
        </p>
        <p>
            <span>·有违反中华人民共和国的法律、法规的行为或言论；</span>
        </p>
        <p>
            <span>·有违背社会风俗、社会道德和互联网一般道德和礼仪的行为；</span>
        </p>
        <p>
            <span>·其他妨碍良药邦提供服务或良药邦认为的严重不当行为；</span>
        </p>
        <p>
            <span>同一用户若有任一帐号存在上述任一行为的，良药邦有权对该用户下的所有帐号予以制裁，包括但不限于冻结帐号、删除帐号、终止服务等。良药邦因上述原因删除用户注册的帐号后即不再对用户承担任何义务和责任。</span>
        </p>
        <p>
            <span>6.3用户同意保障和维护良药邦及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给良药邦或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span>
        </p>
        <p>
            <span>6.4您了解良药邦上的信息系商家自行发布，且可能存在风险和瑕疵。良药邦将通过依照法律规定建立完善相关审核及检查监控制度尽可能保障您在良药邦的合法权益及良好体验。同时，鉴于信息网络环境下信息与实物相分离的特点，良药邦无法逐一审查每一笔交易实际上所涉及的实物产品及/或服务，对此您应谨慎判断，如您有任何售后问题或有任何侵权商品应谨慎判断，如您有任何售后问题或有任何侵权商品的发现，请第一时间联系我们，良药邦将竭诚保护您的合法权益。</span>
        </p>
        <p>
            <span>6.5您理解并同意，在争议调处服务中，良药邦的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断，良药邦不保证争议调处决定一定符合您的期望，除法律明确规定及存在故意或重大过失外，良药邦对争议调处决定不承担责任。</span>
        </p>
        <p>
            <span>6.6本平台上的商品价格（价格范围、市场价）、数量、总库存、上架或下架状态等商品信息随时都有可能发生变动，本平台不作特别通知。由于平台上商品信息的数量极其庞大，虽然本平台会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解。</span>
        </p>
        <p>
            <span><b>6.7除法律另有强制性规定外，双方约定如下：本站上销售方展示的商品和价格等信息仅仅是邀约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（ 以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了合同关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了合同关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立合同关系。</b></span>
        </p>
        <p><span>7.修改协议</span></p>
        <p>
            <span>7.1根据国家法律法规变化及维护市场秩序、保护用户合法权益等需要，良药邦将可能不时的修改本协议的有关条款，一旦条款内容发生变动，良药邦将会在相关的页面提示修改内容。</span>
        </p>
        <p>
            <span>7.2如果用户不同意良药邦修改的内容，有权于变更事项确定的生效日前联系我们反馈意见。如反馈意见得以采纳，我们将酌情调整变更事项。如用户对已生效的变更事项仍不同意的，应当于变更事项确定的生效之日起停止使用良药邦服务，如果用户在变更事项生效后仍继续使用良药邦服务，则视为接受条款的变更。</span>
        </p>
        <p>
            <span>8.法律管辖</span>
        </p>
        <p>
            <span>8.1本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。</span>
        </p>
        <p>
            <span>8.2如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，用户和良药邦一致同意交由良药邦公司所在地有管辖权的法院管辖。</span>
        </p>
        <p>
            <span>9.有效通知</span>
        </p>
        <p>
            <span>9.1有效联系方式</span>
        </p>
        <p>
            <span>·您在注册成为良药邦用户，并接受良药邦服务时，您应该向良药邦提供真实有效的联系方式(包括您的电子邮件地址、联系电话、联系地址等)，对于联系方式发生变更的，您有义务及时更新有关信息，并保方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。</span>
        </p>
        <p>
            <span>·良药邦将向您的上述联系方式的其中之一或其中若王向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</span>
        </p>
        <p>
            <span>·您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的产品/服务广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过相应的退订功能进行退订或者直接联系我们。</span>
        </p>
        <p>
            <span>9.2送达</span>
        </p>
        <p>
            <span>·本协议履行过程中，我们向您发出的书面通知方式包括但不限于邮寄纸质通知、良药邦公告、电子邮件、站内信、良药邦系统通知、手机短信和传真等方式。如我们以邮寄方式发出通知的，则按照您在良药邦平台留存的通讯地址交邮后的第三个自然日即视为送达，如以良药邦公告、电子邮件、良药邦系统通知、手机短信和传真等电子方式发出书面通知的，则在通知发送成功即视为送达。若用户变更通知、通讯地址或其他联系方式，应自变更之日起及时将变更后的地址、联系方式通知我们，否则用户应对此造成的一切后果承担责任。</span>
        </p>
        <p>
            <span>10.其他</span>
        </p>
        <p>
            <span>10.1如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</span>
        </p>
        <p>
            <span>10.2因良药邦公司上市、被收购、与第三方合并、名称变更等事由，用户同意良药邦公司可以将其权利和/或义务转让给相应的权利/义务的承受者。</span>
        </p>
        <p>
            <span>10.3用户在此再次保证已经完全阅读并理解了上述条款并自愿正式成为良药邦平台注册用户，接受上述所有条款的约束。</span>
        </p>
    </div>
</template>

<script>
export default {
    name: "Service", // 用户服务协议
}
</script>

<style lang="scss" scoped>
.service {
    padding: 20px;
    span {
        font-size:16px;
        line-height:2;
    }
}
</style>
